@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  min-height: 100%;
}

@layer base {
  html {
    font-family: "Inter", sans-serif;
    scroll-behavior: smooth;
  }
}

.tooltip-container {
  border-radius: 10px !important;
  font-size: 0.8rem;
  color: #374151 !important;
  border: 1px solid #cbd5e1 !important;
}

.custom-paragraph-style {
  animation: change-color 2s ease-out;
}

@keyframes change-color {
  0% {
    background-color: #60a5fa;
  }
  100% {
    background-color: transparent;
  }
}
